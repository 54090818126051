<template>
  <div class="col-12 col-lg-6 c g"><br>

    <div class="card card-body">
        <div class="col-12 text-center" v-if="loading">
            <img :src="require('@/assets/images/loading.svg')" alt="">
        </div>
        <div class="col-12" v-if="error">
            <div class="alert alert-danger text-center g">
                <h4>
                    {{ error }}
                </h4>
            </div>
        </div>
        <div v-if="!loading && student.name">
            <div class="col-12 text-center">
                <h1>متبقي <strong class="text-danger">{{ student.balance }}</strong> ريال </h1>
                <h3>
                    {{ student.name }}
                </h3>
                <p class="text-muted">{{ student.number }} &mdash; {{ student.phone }}</p><br>
            </div>
            <div class="col-12 text-center" v-if="loading">
                <img :src="require('@/assets/images/loading.svg')" alt="">
            </div>
            <div v-if="!loading">
                <div class="strike">
                    <span>سجل شحن الرصيد</span>
                </div>
                <div class="col-12 table-responsive">
                    <table class="table table-sm table-bordered">
                        <thead>
                            <th>
                                التاريخ
                            </th>
                            <th>
                                المبلغ
                            </th>
                            <th>
                                الرصيد قبل
                            </th>
                            <th>
                                الرصيد بعد
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="c in student.charges" :key="c._id">
                                <td>{{ c.date }}</td>
                                <td>{{ c.amount }}</td>
                                <td>{{ c.balance_before }}</td>
                                <td>{{ c.balance_after }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="strike">
                    <span>سجل السحب</span>
                </div>
                <div class="col-12 table-responsive">
                    <table class="table table-sm table-bordered">
                        <thead>
                            <th>
                                التاريخ
                            </th>
                            <th>
                                المبلغ
                            </th>
                            <th>
                                الرصيد قبل
                            </th>
                            <th>
                                الرصيد بعد
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="c in student.history" :key="c._id">
                                <td>{{ c.date }}</td>
                                <td>{{ c.amount }}</td>
                                <td>{{ c.balance_before }}</td>
                                <td>{{ c.balance_after }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div><center style='color: #999'>نظام مصروفي &nbsp;&mdash;&nbsp; masrufi.brmja.com</center>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            student: {},
            loading: false,
            error: ""
        }
    },
    created(){
        var g = this;
        this.loading = true
        $.post(api + '/user/students/overview', {
            id: window.location.href.split("overview/")[1]
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                g.error = r.response
            }else{
                g.student = r.response
            }
        }).fail(function(){
            g.loading = false
            g.error = "حدث مشكلة في الاتصال"
        })
    }
}
</script>

<style scoped>
.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap; 
}

.strike > span {
    position: relative;
    display: inline-block;
}

.strike > span:before,
.strike > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #eee;
}

.strike > span:before {
    right: 100%;
    margin-right: 15px;
}

.strike > span:after {
    left: 100%;
    margin-left: 15px;
}
</style>